import axios from 'axios'
import Cookies from 'js-cookie'
import { useStore } from '../stores/store'
import { useToast } from 'vue-toast-notification'

const $toast = useToast({position: 'top'});

// let csrfToken: string = Cookies.get('csrftoken') || ""
let authToken: string = Cookies.get('auth_token') || ""

const instance = axios.create({
    baseURL: import.meta.env.VITE_API_BASE
})

instance.interceptors.request.use(
    (config) => {
      config.headers['X-CSRFToken'] = Cookies.get('csrftoken') || ""
      config.headers['Authorization'] = 'Token ' + authToken
      config.withCredentials = true
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    console.log(error)
    $toast.error(error.response.data.error || error.response.data.detail || error.response.data.non_field_errors || error.message)
    if (error.response.status == 401) {
      Cookies.remove('csrftoken')
      const store = useStore()
      store.logOut()
    }
    return Promise.reject(error);
  }
)

export default instance