<template>
    <div class="flex flex-col h-full">
        <div class="flex-1">
            <div class="text-gray text-sm mb-4">Создание плана {{ dayjs(shift.date).format('DD.MM.YYYY') }}</div>
            <div class="flex mb-5">
                <Avatar size="md" />
                <div class="ml-4">
                    <div class="text-sm semibold text-darkgray">{{ schedule.last_name }} {{ schedule.first_name }} {{
                        schedule.middle_name }}
                    </div>
                    <div class="text-xs text-gray">{{ shift.position.name }}</div>
                    <div class="text-xs text-gray">{{ schedule.department.name }}</div>
                </div>
            </div>

            <EmployeeStats class="mb-3" :norm="schedule.shift_schedule.norm_hours" :salary="schedule.salary_hour"
                :shifts-number="schedule.shifts.length" :plan="planHours" :extra="extraHours" :main="mainHours" />

            <div class="flex border-b border-grayxlight mb-4">
                <div class="text-[13px] text-darkgray semibold mr-4 border-b border-x-darkblue">1 смена</div>
                <div class="text-[11px] text-gray">+ Добавить смену</div>
            </div>

            <div class="flex justify-between mb-4">
                <div class="border-b border-grayxlight text-[13px]">Основные данные</div>
                <button-icon class="text-darkred opacity-40">
                    <IconTrash />
                </button-icon>
            </div>

            <div class="flex mb-4">
                <div class="mr-5">
                    <base-input v-model="dayType">Тип дня</base-input>
                </div>
                <div class="mr-5">
                    <base-input v-model="position">Должность</base-input>
                </div>
            </div>

            <checkbox class="text-sm">Дополнительный табель</checkbox>

            <div class="flex justify-between mb-4">
                <div class="border-b border-grayxlight text-[13px]">Плановая смена</div>
            </div>

            <div class="flex">
                <div class="mr-5">
                    <base-input v-model="start">Время начала</base-input>
                </div>
                <div class="mr-5">
                    <base-input v-model="end">Время окончания</base-input>
                </div>
            </div>
        </div>

        <div class="text-right pt-5">
            <btn class="mr-7">Сохранить</btn>
            <btn class="mr-5" type="secondary">Отменить</btn>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Schedule, Shift } from '../../types';

interface Props {
    schedule: Schedule,
    shift: Shift
}

import { ref, onMounted } from 'vue'
import Avatar from '../Avatar.vue'
import EmployeeStats from './EmployeeStats.vue'
import IconTrash from '../../ui/icons/IconTrash.vue'
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

dayjs.locale('ru')
dayjs.extend(utc)

const { schedule, shift } = defineProps<Props>()

const start = ref()
const end = ref()
const planHours = ref(0)
const extraHours = ref(0)
const mainHours = ref(0)

const dayType = shift.day_type.name
const position = shift.position.name

start.value = dayjs.utc(shift.plan_work_start_at).format('HH:mm')
end.value = dayjs.utc(shift.plan_work_end_at).format('HH:mm')

function getShiftHours(start: string, end: string) {
    return dayjs(end).diff(dayjs(start), 'hour', true)
}

function calcHours() {
    schedule.shifts.forEach(shift => {
        planHours.value += getShiftHours(shift.plan_work_start_at, shift.plan_work_end_at)
        if (shift.is_overtime) {
            extraHours.value += getShiftHours(shift.plan_work_start_at, shift.plan_work_end_at)
        } else {
            mainHours.value += getShiftHours(shift.plan_work_start_at, shift.plan_work_end_at)
        }
    })
}

onMounted(() => {
    calcHours()
})

</script>
