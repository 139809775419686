import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import './style.css'
import './assets/css/theme-mindm.css'
import App from './App.vue'
import router from './router'
import Logo from './ui/Logo.vue'
import Button from './ui/Button.vue'
import Input from './ui/Input.vue'
import BaseInput from './ui/BaseInput.vue'
import BaseButton from './ui/BaseButton.vue'
import Icon from './ui/Icon.vue'
import Checkbox from './ui/Checkbox.vue'
import ButtonIcon from './ui/ButtonIcon.vue'
import * as Sentry from "@sentry/vue"
import { createI18n } from 'vue-i18n'
import messages from './locales'
import vClickOutside from "click-outside-vue3"


const i18n = createI18n({
    locale: 'ru',
    fallbackLocale: 'ru',
    messages
  })

const app = createApp(App)
const pinia = createPinia()

pinia.use(({ store }) => {
    store.router = markRaw(router)
});

Sentry.init({
    app,
    dsn: "https://2d91c5df072aa20d28fb7593f000e92e@o4507385490309120.ingest.de.sentry.io/4507661265272912",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

app.use(i18n)
app.use(pinia)
app.use(router)
app.use(vClickOutside)
app.mount('#app')

app.component('Logo', Logo)
app.component('b-button', Button)
app.component('b-input', Input)
app.component('base-input', BaseInput)
app.component('btn', BaseButton)
app.component('icon', Icon)
app.component('checkbox', Checkbox)
app.component('button-icon', ButtonIcon)