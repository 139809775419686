import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getDepartmentTree, getSchedule } from '../api/auth'
import { Schedule } from '../types'
// import { useToast } from 'vue-toast-notification'

// const $toast = useToast({position: 'bottom'});

export const useStore = defineStore('Store', () => {
    const schedules = ref<Schedule[]>([])

    async function fetchDepartmentsTree() {
        try {
            const res = await getDepartmentTree()
            return res.data.body
        } catch(err:any) {
            return err
        }
    }

    async function fetchSchedule(department:string, start:string, end:string) {
        try {
            const res = await getSchedule(department, start, end)
            return schedules.value = res.data.body
        } catch(err:any) {
            return err
        }
    }

    return {
        fetchDepartmentsTree,
        fetchSchedule,
        schedules
    }
})