<template>
    <div class="text-5xl text-graytitle mb-6">Сброс пароля</div>
    <form class="max-w-md" @submit.prevent="reset">
        <b-input class="mb-10" v-model="username">Введите e-mail / логин</b-input>
        <b-button class="mb-4">Сбросить пароль</b-button>
        <div class="text-center text-darkblue text-lg">
            <router-link to="/login">Вернуться к экрану авторизации</router-link>
        </div>
    </form>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useStore } from '../stores/store'

const username = ref("")

const store = useStore()

async function reset() {
    await store.resetPassword(username.value)
}

</script>
