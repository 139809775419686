import { createWebHistory, createRouter } from "vue-router"
import Login from '../views/Login.vue'
import { useStore } from "../stores/store"
import LoginLayout from "../layouts/LoginLayout.vue"
import MainLayout from "../layouts/MainLayout.vue"
import Schedule from "../views/Schedule.vue"
import Recovery from "../views/Recovery.vue"
import NotFound from "../views/NotFound.vue"
import Employees from "../views/Employees.vue"
import ShiftExchange from "../views/ShiftExchange.vue"
import Analytics from "../views/Analytics.vue"

const routes = [
  {
    path: "/",
    name: 'Home',
    meta: { layout: MainLayout },
    component: Schedule,
  },
  {
    path: "/employees",
    name: 'Employees',
    meta: { layout: MainLayout },
    component: Employees,
  },
  {
    path: "/shift-exchange",
    name: 'ShiftExchange',
    meta: { layout: MainLayout },
    component: ShiftExchange,
  },
  {
    path: "/analytics",
    name: 'Analytics',
    meta: { layout: MainLayout },
    component: Analytics,
  },
  {
    path: "/login",
    name: "Login",
    meta: { layout: LoginLayout },
    component: Login,
  },
  {
    path: "/recovery",
    name: "Recovery",
    meta: { layout: LoginLayout },
    component: Recovery,
  },
  {
    path: "/404",
    name: "NotFound",
    component: NotFound,
  },
  { path: '/:pathMatch(.*)*', redirect: "/404" },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE),
  routes,
});


router.beforeEach(async (to) => {
  const store = useStore()
  const isAuth = true || store.isAuth
  const publicRoutes = ['Login', 'Recovery']

  if (!isAuth && !publicRoutes.includes(String(to.name))) {
    return { name: 'Login' }
  }

  if (isAuth && to.name == 'Login') {
    return { name: 'Home' }
  }

})

export default router;