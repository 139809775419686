<template>
    <div class="relative cursor-pointer">
        <input type="text" v-model="searchInput" @click="openDropdown" @input="search" ref="input"
            class="w-full h-[26px] max-w-lg rounded bg-grayxlight text-gray focus:bg-white focus:border-violet focus:outline-violet hover:border-gray border border-solid border-grayxlight px-3 pl-8">
        <icon class="absolute left-2 top-[6px]">
            <IconMapPin />
        </icon>
        <div v-if="isOpen"
            class="absolute top-full left-0 bg-white w-[639px] p-4 overflow-y-auto z-50 shadow-lg rounded"
            v-click-outside="closeDropdown">
            <div v-for="dep in deps" :key="dep.id" class=" text-gray ">
                <div class="hover:bg-grayxlight px-2 py-1" @click="selectDep(dep)">{{ dep.name }}</div>
                <div v-if="dep.children && !isSearch" v-for="child in dep.children" :key="child.id"
                    class="pl-4 pr-2 py-1 text-gray hover:bg-grayxlight" @click="selectDep(child)">
                    {{ child.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from '../../stores/store'
import IconMapPin from '../../ui/icons/IconMapPin.vue'

const store = useStore()

const props = defineProps({
    current: {}
})

const isOpen = ref(false)
const department = ref()
const departments = ref()
const deps = ref()
const searchInput = ref()
const isSearch = ref(false)
const input = ref(null)

department.value = props.current
searchInput.value = department.value.name

const openDropdown = () => {
    isOpen.value = true
    searchInput.value = ""
};

const closeDropdown = () => {
    isOpen.value = false
    searchInput.value = department.value.name
    input.value.blur()
};

const resetTree = () => {
    deps.value = [...departments.value]
    isSearch.value = false
}

const selectDep = (dep) => {
    department.value = dep
    searchInput.value = department.value.name
    isOpen.value = false
    resetTree()
}

async function getTree() {
    const res = await store.fetchDepartmentsTree()
    console.log(res)
    departments.value = res
    resetTree()
}

function search() {
    if (searchInput.value) {
        let arr = []
        departments.value.forEach(item => {
            arr.push(item)
            if (item.children) { arr.push(...item.children) }
        })
        isSearch.value = true
        deps.value = arr.filter(item => item.name.toLowerCase().includes(searchInput.value.toLowerCase()))
    } else {
        resetTree()
    }

}

onMounted(async () => {
    await getTree()
})
</script>
