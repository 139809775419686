<template>
    <div class="fixed h-screen w-screen bg-black opacity-20 top-0 left-0 z-50" v-if="isActive" @click="toggle"></div>
    <div class="fixed bg-white h-screen top-0 right-0 w-[500px] z-50 transition-transform duration-300 p-4"
        :class="{ 'transform translate-x-full': !isActive }">
        <button @click="toggle" class="absolute top-1 right-1 text-gray">
            <icon>
                <IconClose />
            </icon>
        </button>
        <slot v-if="isActive"></slot>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import IconClose from '../../ui/icons/IconClose.vue'
const isActive = ref(false)
const toggle = () => isActive.value = !isActive.value

defineExpose({
    toggle
});
</script>
