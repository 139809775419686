<template>
    <div>
        <div class="flex justify-center pt-40">
            <b-button @click="store.logOut">Выйти</b-button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useStore } from '../stores/store'

const store = useStore()
</script>
