<template>
    <path
        d="M0.000411987 35.0942V68.813C21.5696 64.7615 28.5391 36.404 28.5391 36.404H12.4387C12.4387 36.404 6.32856 35.7443 0.000411987 35.0942Z"
        fill="#231F20" />
    <path
        d="M0.000518799 21.6306V37.2051C2.66979 39.5605 5.61268 41.5128 8.73651 43.0406C9.21117 43.2718 9.6903 43.4952 10.1739 43.7062C15.3483 45.9824 20.9671 47.1238 26.6296 47.0434C26.6419 47.0434 26.6553 47.0423 26.6675 47.0423C30.18 46.9876 33.7071 46.4626 37.157 45.4463C37.8506 45.2419 38.5151 45.0253 39.1472 44.7963C40.091 44.4568 40.9599 44.0927 41.7394 43.7107C41.9617 43.6024 42.175 43.4929 42.3827 43.3812C43.2628 42.9066 44.0055 42.4074 44.5829 41.8936C44.7427 41.7518 44.8912 41.6077 45.0241 41.4636C45.6428 40.798 45.9757 40.1133 45.9656 39.4332C45.9634 39.2344 45.931 39.0367 45.8673 38.839C45.8483 38.7765 45.8249 38.7139 45.7992 38.6525C44.5327 35.6325 43.7766 32.2049 43.3723 29.9165C43.2003 28.9504 42.9691 27.9989 42.6776 27.0674C41.7082 23.9682 40.0809 21.1023 37.8595 18.7056C36.4679 17.2034 34.9255 15.9391 33.2871 14.8859C32.7834 14.5609 32.2708 14.2571 31.7503 13.9724C30.8502 13.4787 29.9265 13.042 28.9884 12.6612C28.2814 12.3741 27.5655 12.1173 26.8474 11.8894C26.3012 11.7163 25.7517 11.56 25.2034 11.4215C24.5779 11.2997 23.9447 11.1981 23.3058 11.1177C22.6916 11.0384 22.0728 10.9803 21.4496 10.9423C20.23 10.8675 18.9959 10.872 17.7562 10.9703C17.3966 10.9982 17.0358 11.0339 16.6751 11.0775C14.6581 11.3221 12.6343 11.8191 10.6497 12.6187C7.73136 13.7959 5.12574 15.6007 2.97469 17.8668C2.22081 18.662 1.52279 19.5119 0.886181 20.4132C0.620369 20.7874 0.324402 21.1973 0.000518799 21.6306Z"
        fill="#231F20" />
    <path
        d="M2.52729 16.3396C2.71318 17.0701 2.94287 17.8148 3.22229 18.5714C7.10931 29.1017 16.7031 11.027 16.7031 11.027C16.7031 11.027 11.2272 2.16254 6.89145 0.179365C6.33379 -0.0751915 5.71695 -0.0503254 5.20191 0.199495C4.83014 0.379461 4.51165 0.676641 4.30682 1.06972C3.03522 3.51347 0.72882 9.26881 2.52729 16.3396Z"
        fill="#231F20" />
    <path
        d="M2.52648 16.3394C5.18548 14.0445 8.46637 12.6151 7.14337 5.30137C6.6338 2.48158 5.91614 0.971781 5.20085 0.198555C4.8296 0.378265 4.51038 0.676208 4.30584 1.06991C3.03487 3.51255 0.728192 9.268 2.52648 16.3394Z"
        fill="#4D4D4D" />
    <path
        d="M43.9796 25.5162C43.5236 26.1156 43.019 26.7104 42.4659 27.2963C34.7575 35.4562 33.0271 15.066 33.0271 15.066C33.0271 15.066 41.5426 9.06211 46.3085 8.9402C46.9216 8.9244 47.4792 9.18966 47.8547 9.62085C48.1264 9.93239 48.3023 10.3308 48.3363 10.7733C48.5468 13.5196 48.4101 19.7177 43.9796 25.5162Z"
        fill="#231F20" />
    <path
        d="M43.9801 25.5156C42.435 22.3625 39.9791 19.7604 44.0656 13.5521C45.6414 11.158 46.8938 10.0515 47.8546 9.62083C48.1264 9.93231 48.3022 10.3315 48.3363 10.7734C48.5468 13.5195 48.4102 19.7177 43.9801 25.5156Z"
        fill="#4D4D4D" />
    <path
        d="M24.9606 30.9231C24.9606 30.9231 27.7348 24.7463 32.8913 25.4901C38.0478 26.234 39.1063 31.3843 39.0103 32.1818C38.9144 32.9793 36.5342 36.2962 31.5676 36.1672C26.601 36.0393 24.5211 31.7515 24.9606 30.9231Z"
        fill="#FFC60B" />
    <path
        d="M27.2741 29.9142C26.7375 32.3134 28.2468 34.692 30.646 35.2286C33.0441 35.7652 35.4239 34.2558 35.9604 31.8577C36.496 29.4596 34.9866 27.0799 32.5885 26.5433C30.1903 26.0067 27.8106 27.5161 27.2741 29.9142Z"
        fill="#231F20" />
    <path
        d="M17.1143 29.1673C17.1143 29.1673 17.2368 22.3972 12.2546 20.8735C7.2723 19.3497 4.12046 23.5579 3.8681 24.3203C3.61574 25.0827 4.35578 29.097 8.90359 31.0967C13.4514 33.0975 17.1591 30.1054 17.1143 29.1673Z"
        fill="#FFC60B" />
    <path
        d="M6.76554 25.3269C6.22896 27.7258 7.73874 30.1046 10.1365 30.6412C12.5353 31.1777 14.9142 29.669 15.4507 27.2702C15.9873 24.8714 14.4786 22.4925 12.0798 21.956C9.68094 21.4194 7.30212 22.9292 6.76554 25.3269Z"
        fill="#231F20" />
    <path
        d="M18.5702 33.2774C18.9323 32.9742 19.5466 33.1204 19.9606 33.2087C20.0715 33.2322 20.1893 33.2558 20.3109 33.2823C20.4365 33.3107 20.5533 33.3402 20.6642 33.3657C21.0763 33.4628 21.6935 33.5924 21.8917 34.0202C22.138 34.551 21.669 35.0563 21.2637 35.335C20.8123 35.6461 20.2884 35.8227 19.7899 35.6225C19.2532 35.5911 18.8548 35.2075 18.5781 34.7345C18.3308 34.3096 18.1218 33.6522 18.5702 33.2774Z"
        fill="#4D4D4D" />
    <path
        d="M13.6617 35.3131C14.187 36.4063 15.318 37.1913 16.5169 37.3522C17.1372 37.4356 17.7497 37.265 18.2711 36.9267C18.5522 36.7455 18.8042 36.5013 18.9796 36.2173C19.1851 35.882 19.2568 35.4914 19.4477 35.1512C19.5359 34.9952 19.7734 34.937 19.9188 35.0494C20.0971 35.0097 20.287 35.1628 20.3006 35.3421C20.3287 35.7308 20.2269 36.1155 20.2706 36.5052C20.3074 36.8376 20.4324 37.1661 20.6078 37.4491C20.9364 37.9783 21.4181 38.3931 22.0151 38.5821C23.1675 38.9475 24.5243 38.7197 25.4664 37.9531C25.7581 37.7166 26.232 38.0074 26.0188 38.3747C25.1494 39.8702 22.68 40.1745 21.2126 39.4767C20.4053 39.0929 19.7094 38.3951 19.3557 37.5654C18.6811 38.1654 17.7546 38.4997 16.861 38.5036C15.2366 38.5094 13.1316 37.1816 12.9833 35.4584C12.9464 35.0349 13.4989 34.9748 13.6617 35.3131Z"
        fill="#4D4D4D" />
    <path
        d="M32.1362 40.0197C32.6523 39.905 33.19 39.9248 33.7117 39.8637C34.3011 39.7951 34.8989 39.7857 35.4911 39.7593C36.6906 39.7058 37.8909 39.6935 39.0941 39.8073C41.4395 40.0282 43.7368 40.7604 45.9646 41.9279C47.2863 42.6207 48.5694 43.4733 49.8497 44.3155C49.985 44.4039 49.8995 44.6972 49.7641 44.6107C47.7206 43.3041 45.5802 42.2174 43.4172 41.4495C40.9817 40.5847 38.5565 40.4324 36.0795 40.3609C35.462 40.3431 34.8444 40.3609 34.2287 40.375C33.5378 40.391 32.8177 40.4625 32.1268 40.3675C31.9764 40.3468 32.0103 40.0479 32.1362 40.0197Z"
        fill="#4D4D4D" />
    <path
        d="M32.0631 38.9361C32.5671 38.7475 33.1007 38.6903 33.6133 38.5541C34.1926 38.4007 34.7843 38.3054 35.3694 38.1939C36.5547 37.9681 37.7419 37.7832 38.9434 37.7232C41.2835 37.606 43.6112 38.0072 45.9017 38.8523C47.2604 39.3544 48.5925 40.0214 49.9216 40.6779C50.0617 40.7474 49.9979 41.0523 49.8569 40.9856C47.7397 39.9747 45.5416 39.1972 43.3434 38.7408C40.868 38.2282 38.4546 38.4245 35.9954 38.7094C35.3827 38.7808 34.7719 38.8875 34.1621 38.9905C33.479 39.1057 32.7701 39.2811 32.0783 39.2858C31.9278 39.2868 31.9402 38.9828 32.0631 38.9361Z"
        fill="#4D4D4D" />
    <path
        d="M32.1805 41.1922C32.6721 41.1596 33.173 41.2555 33.6646 41.2741C34.2213 41.2946 34.7809 41.3719 35.3367 41.4324C36.4623 41.5553 37.585 41.7154 38.7032 41.9938C40.8808 42.5357 42.9775 43.5449 44.9782 44.947C46.1652 45.7793 47.3048 46.7541 48.4434 47.7195C48.5626 47.821 48.463 48.0808 48.3419 47.9802C46.5227 46.4757 44.5983 45.1602 42.6293 44.1379C40.4125 42.9863 38.1558 42.4966 35.8441 42.0748C35.2678 41.9696 34.6896 41.897 34.1124 41.8216C33.4653 41.7378 32.7866 41.7006 32.147 41.5134C32.0074 41.4725 32.0604 41.1997 32.1805 41.1922Z"
        fill="#4D4D4D" />
    <path
        d="M0.000701427 30.9987V31.7576C1.02882 32.1112 2.03011 32.5304 3.03439 32.9685C3.60059 33.2158 4.1519 33.4949 4.70321 33.7701C5.32107 34.079 5.94191 34.4505 6.60745 34.6591C6.75347 34.7048 6.84983 34.4188 6.74752 34.3403C6.32932 34.0165 5.83463 33.8049 5.38862 33.5277C4.88499 33.2148 4.34759 32.9516 3.8241 32.6754C2.76022 32.1162 1.68045 31.5937 0.543065 31.1834C0.364263 31.1189 0.182484 31.0563 0.000701427 30.9987Z"
        fill="#4D4D4D" />
    <path
        d="M-1.14441e-05 28.6313V29.4003C1.3203 29.997 2.56603 30.7358 3.81378 31.5108C4.33787 31.8363 4.84482 32.1931 5.35279 32.5459C5.92224 32.941 6.48866 33.4016 7.11153 33.7009C7.24759 33.7654 7.36652 33.4852 7.2748 33.3915C6.89988 33.0055 6.44129 32.7253 6.03512 32.3846C5.57654 31.9986 5.08168 31.661 4.60092 31.3102C3.62429 30.6007 2.62852 29.9274 1.56723 29.362C1.05725 29.0899 0.535168 28.847 -1.14441e-05 28.6313Z"
        fill="#4D4D4D" />
    <path
        d="M0.000496387 33.3351V34.0135C0.845383 34.1858 1.6795 34.3963 2.51656 34.6185C3.08242 34.7693 3.63654 34.9504 4.19164 35.1286C4.81233 35.3274 5.44184 35.5829 6.10071 35.6867C6.24463 35.7092 6.31218 35.4399 6.20743 35.3812C5.77666 35.1433 5.28226 35.016 4.82898 34.8222C4.31794 34.6048 3.7785 34.4355 3.24983 34.2544C2.18956 33.8892 1.11657 33.5593 0.000496387 33.3351Z"
        fill="#4D4D4D" />
</template>