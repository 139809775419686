<template>
    <div class="overflow-x-auto overflow-y-auto relative h-[calc(100vh-123px)]">
        <div class="sticky top-0 z-50">
            <div class="flex z-10 bg-grayxlight sticky">
                <div class="min-w-60 sticky left-0 flex bg-grayxlight z-10">
                    <div class="w-[200px] h-12 flex items-center">
                        <input type="text" class="w-40 h-6 rounded text-xs px-2 mx-1.5" placeholder="Поиск...">
                        <button-icon class="bg-white text-gray hover:text-darkblue" width="17" height="17">
                            <IconFilter />
                        </button-icon>
                    </div>
                    <div class="w-11 pl-2 pt-2 text-xs">
                        <div class="text-darkred">
                            <Dropdown :options="work" v-model="option">{{ cut(option?.title, 3) }}</Dropdown>
                        </div>
                        <div class="text-darkblue">
                            <Dropdown :options="test" v-model="option1">{{ cut(option1?.title, 3) }}</Dropdown>
                        </div>
                    </div>
                </div>
                <div class="flex flex-1 bg-grayxlight">
                    <div v-for="day in days"
                        class="group relative min-w-12 w-full text-xs text-center pt-2 pb-2 text-gray">
                        <div class="semibold ">{{ day }}.02</div>
                        <div>ПН</div>
                        <InfoTooltip />
                    </div>
                </div>
            </div>

            <div class="flex z-10 bg-white">
                <div class="min-w-60 sticky left-0 flex justify-end bg-white">
                    <div class="pt-1 text-xs text-right">
                        <div class="text-darkred text-left">
                            <Dropdown :options="test1" v-model="opt1">{{ cut(opt1?.title, 8) }}</Dropdown>
                        </div>
                        <div class="text-darkblue text-left">
                            <Dropdown :options="test2" v-model="opt2">{{ cut(opt2?.title, 8) }}</Dropdown>
                        </div>
                    </div>
                    <div class="pt-1 pl-2 w-11 text-xs">
                        <div class="text-darkred">100%</div>
                        <div class="text-darkblue">100%</div>
                    </div>
                </div>
                <div class="flex flex-1">
                    <div v-for="day in days" :key="day" class="min-w-12 w-full text-xs text-center pt-1 bg-white">
                        <div class="text-darkred">100%</div>
                        <div class="text-darkblue">100%</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex bg-white" v-for="item in days" :key="item">
            <div class="min-w-60 sticky left-0 bg-white pt-2 pb-1 z-10">
                <Employee />
            </div>

            <div class="flex flex-1">
                <div v-for="(day, index) in days" :key="day"
                    class="group min-w-12 w-full flex items-center justify-center cursor-pointer border-red relative"
                    :class="{ 'bg-grayxlight': item % 2 === 0, 'border-r': index === 10 }" @click="openSidePanel">
                    <icon>
                        <IconPlus />
                    </icon>
                    <!-- <Tooltip text="Переведён в МП369" /> -->
                </div>
            </div>
        </div>
    </div>
    <StatBar />
    <SidePanel ref="sidebar">
        <ShiftInfo :schedule="store.schedules[0]" :shift="store.schedules[0].shifts[1]" />
    </SidePanel>

</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useStore } from '../stores/store'
import IconFilter from '../ui/icons/IconFilter.vue'
import IconPlus from '../ui/icons/IconPlus.vue'
import Dropdown from '../components/Dropdown.vue'
import StatBar from '../components/schedule/StatBar.vue'
import Employee from '../components/schedule/Employee.vue'
// import Tooltip from '../components/schedule/Tooltip.vue'
import InfoTooltip from '../components/schedule/InfoTooltip.vue'
import SidePanel from '../components/schedule/SidePanel.vue'
import ShiftInfo from '../components/schedule/ShiftInfo.vue'

const store = useStore()

const option = ref()
const option1 = ref()
const opt1 = ref()
const opt2 = ref()

const cut = (text: string, num: number) => text.slice(0, num)
const sidebar = ref()
const openSidePanel = () => sidebar.value.toggle()
const department = '5514f89c-cec2-4788-89a6-b4e3b28adc58'
const start = '2024-10-01'
const end = '2024-10-30'

onMounted(async () => {
    await store.fetchSchedule(department, start, end)
})

const days = 31
const work = [
    { title: 'Рабочих дней в других магазинах', value: 'other', },
    { title: 'Рабочих дней в текущем магазине', value: 'current' },
    { title: 'Рабочих дней во всех магазинах', value: 'all' },
    { title: 'Норма часов на текущий месяц (произв. календарь)', value: 'n-month' },
    { title: 'Норма часов на текущий мес. (без уменьшения)', value: 'nm' },
    { title: 'Рабочих дней в других магазинах', value: 'other1', },
    { title: 'Рабочих дней в текущем магазине', value: 'current1' },
    { title: 'Рабочих дней во всех магазинах', value: 'all1' },
    { title: 'Норма часов на текущий месяц (произв. календарь)', value: 'n-month1' },
    { title: 'Норма часов на текущий мес. (без уменьшения)', value: 'nm1' }
]

const test = [
    { title: 'Норма часов на текущий мес. (без уменьшения)', value: 'nm1' },
    { title: 'Рабочих дней в других магазинах', value: 'other', },
    { title: 'Рабочих дней в текущем магазине', value: 'current' },
    { title: 'Рабочих дней во всех магазинах', value: 'all' },
    { title: 'Норма часов на текущий месяц (произв. календарь)', value: 'n-month' },
    { title: 'Норма часов на текущий мес. (без уменьшения)', value: 'nm' },
    { title: 'Рабочих дней в других магазинах', value: 'other1', },
    { title: 'Рабочих дней в текущем магазине', value: 'current1' },
    { title: 'Рабочих дней во всех магазинах', value: 'all1' },
    { title: 'Норма часов на текущий месяц (произв. календарь)', value: 'n-month1' }
]

const test1 = [
    { title: 'Покрытие', value: 'nm1' },
    { title: 'Рабочих дней в других магазинах', value: 'other', },
    { title: 'Рабочих дней в текущем магазине', value: 'current' },
    { title: 'Рабочих дней во всех магазинах', value: 'all' },
    { title: 'Норма часов на текущий месяц (произв. календарь)', value: 'n-month' },
    { title: 'Норма часов на текущий мес. (без уменьшения)', value: 'nm' },
    { title: 'Рабочих дней в других магазинах', value: 'other1', },
    { title: 'Рабочих дней в текущем магазине', value: 'current1' },
    { title: 'Рабочих дней во всех магазинах', value: 'all1' },
    { title: 'Норма часов на текущий месяц (произв. календарь)', value: 'n-month1' }
]

const test2 = [
    { title: 'Простой', value: 'nm1' },
    { title: 'Рабочих дней в других магазинах', value: 'other', },
    { title: 'Рабочих дней в текущем магазине', value: 'current' },
    { title: 'Рабочих дней во всех магазинах', value: 'all' },
    { title: 'Норма часов на текущий месяц (произв. календарь)', value: 'n-month' },
    { title: 'Норма часов на текущий мес. (без уменьшения)', value: 'nm' },
    { title: 'Рабочих дней в других магазинах', value: 'other1', },
    { title: 'Рабочих дней в текущем магазине', value: 'current1' },
    { title: 'Рабочих дней во всех магазинах', value: 'all1' },
    { title: 'Норма часов на текущий месяц (произв. календарь)', value: 'n-month1' }

]
</script>
