<template>
    <form class="max-w-md pr-4" @submit.prevent="Login">
        <h3 class="text-5xl text-graytitle mb-6">{{ $t('loginForm.title') }}</h3>
        <b-input class="mb-12" v-model="username">{{ $t('loginForm.login') }}</b-input>
        <b-input type="password" class="mb-3" v-model="password">{{ $t('loginForm.password') }}</b-input>
        <div class="flex justify-between">
            <router-link to="/recovery">{{ $t('loginForm.forgotPassword') }}</router-link>
        </div>
        <b-button class="mb-2 mt-8">{{ $t('loginForm.enterBtn') }}</b-button>
    </form>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useStore } from '../stores/store'

const username = ref("")
const password = ref("")

const store = useStore()

async function Login() {
    await store.signIn({ username: username.value, password: password.value })
}

</script>
